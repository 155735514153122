
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #B0B0B0 !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #03665c!default;
$black:    #000 !default;

$blue:    #007bff !default;
$indigo:  #24a2b7 !default;
$purple:  #6f42c1 !default;
$pink:    #ff498b !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #008080 !default;
$cyan:    #17a2b8 !default;