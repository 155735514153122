.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  border-radius: 6px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6px;
  overflow: hidden;
}