/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import "color-variables";
@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "utilities";
@import "print";
@import "container-grid";

.brand-text-icon {
  font-size: 18px;
  font-weight: 600;
  font-family: "Poopins", sans-serif;
  color: $primary;
  &:hover {
    text-decoration: none;
  }
  .logo {
    color : $indigo
  }
  .showroom {
    color: $gray-900
  }
}

@media (max-width: 1450px) {
  .brand-text-icon {
    font-size: 16px;
  }
}

@media (max-width: 1300px) {
  .brand-text-icon {
    font-size: 15px;
  }
}

.logo-icon {
  @media only screen and (min-width: 320px) {
    display: none;
  }
}

.logo-icon {
  display: inline;
}

.theater-title {
  @media only screen and (max-width: 400px) {
    font-size: 22px;
  }
}

.brand-text-icon {
  @media only screen and (max-width: 700px) {
    font-size: 22px;
  }
}

.brand-text-icon {
  @media only screen and (max-width: 350px) {
    font-size: 20px;
  }
}

.discord-text {
  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

.sticky-comment {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 99;
}

.layout {
  margin-top: 30px;
  padding-left: 5px;
  padding-right: 5px;
}


@media (max-width: 768px) {
  .layout {
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1280px) {
  .detail-layout {
    padding: 0 !important;
  }
}

.main-sidebar {
  background-color: #21252b;
  height: 100vh;
  padding: 12px;
}

.sidebar {
  margin-top: 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.sticky-sidebar {
  position: sticky;
  top: 0;
}

header {
  border-bottom: 2px solid $gray-300;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  background-color: ghostwhite;

  .navbar {
    height: 70px;

    ul.navbar-nav {
      li.nav-item {
        &.active {
          a.nav-link {
            color: $primary;
            font-weight: 500;
          }
        }
        a {
          &.nav-link {
            letter-spacing: 1px;
            color: $gray-900;
            padding-left: 1rem;
            padding-right: 1rem;
            &:hover {
              color: $indigo
            }
          }
        }
      }
    }
  }
}

section {
  margin-bottom: 70px;
}

.idn-live {
  background-color: #21252B;
  .logo {
    cursor: pointer;
    font-size: 0.875rem;
    background-color:white;
    padding: 0.25rem 1rem;
    position: absolute;
    top: 0; right:0;
    color: $white;
    min-height: 30px;
    border-radius: 0 8px 0 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 2px;
    z-index: 10;
    span {
      font-weight: 300;
    }
  }

  .logo-multi {
    z-index: 10;
    cursor: pointer;
    font-size: 0.875rem;
    padding: 0.25rem 1rem;
    position: absolute;
    top: 0; right:0;
    color: $white;
    min-height: 30px;
    border-radius: 0 8px 0 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 2px;
    span {
      font-weight: 300;
    }
  }

  .views {
    z-index: 3;
    font-size: 0.875rem;
    background-color:#dc3545;
    padding: 0.25rem 1rem;
    position: absolute;
    top: 0; left:0;
    color: $white;
    min-height: 40px;
    border-radius: 8px 0 15px 0;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 2px;
    span {
      font-weight: 300;
    }
  }
}

@media only screen and (max-width: 450px) {
  .idn-live {
    background-color: #21252B;
  
    .views {
      padding: 0.25rem 0.50rem;
      min-height: 30px;
      font-size: 14px;
    }
  }

  .leaderboard-text {
    font-size: 20px !important;
    font-weight: bold;
  }
}

.card {
  .tag {
    z-index: 3;
    font-size: 0.875rem;
    background-color:#22a2b7;
    padding: 0.25rem 1rem;
    position: absolute;
    top: 0; right:0;
    color: $white;
    min-height: 30px;
    border-radius: 0 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 4px;
    span {
      font-weight: 300;
    }
  }

  .img-wrapper {
    height: 100%;
    margin-bottom: 0;
    border-radius: 0.9375rem;
    overflow: hidden;
  }

  .meta-wrapper {
    margin-top: 1rem;
    h5 {
      font-size: 1.125rem;
      margin-bottom: 2px;
    }
    span {
      font-size: 0.875rem;
    }
  }

  &.card-featured {
    overflow: hidden;
    cursor: pointer;
    height: 100%;
    border-radius: 15px;
    &:after {
      position: absolute;
      content: "";
      top: 0; left: 0; right: 0; bottom: 0;
      transform: scale(1.1);
      transition: 0.5s transform ease-in-out;
      z-index: 2;
      @include gradient-y(rgba($black, 0), rgba($black, 0.51), 0%, 100%);
    }

    .img-wrapper {
      z-index: 1;
      margin: 0;
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      transform: scale(1);
      transition: 0.25s transform ease-in-out;
      border-radius: $border-radius;
    }

    .meta-wrapper {
      z-index: 4;
      color: $white;
      padding: 20px;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      transform: translateY(0);
      transition: 0.3s transform ease-in-out;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
    }

    &:hover {
      .meta-wrapper {
        transform: translateY(-10px);
      }
      &:after {
        transform: scale(1.1);
      }
      .img-wrapper {
        transform: scale(1.1);
      }
    }
  }

  &.card-setlist {
    overflow: hidden;
    cursor: pointer;
    height: 215px;
    border: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &:after {
      position: absolute;
      content: "";
      top: 0; left: 0; right: 0; bottom: 0;
      transform: scale(1.1);
      transition: 0.5s transform ease-in-out;
      z-index: 2;
      @include gradient-y(rgba($black, 0), rgba($black, 0.31), 0%, 100%);
    }

    .img-wrapper {
      z-index: 1;
      margin: 0;
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      transform: scale(1);
      transition: 0.25s transform ease-in-out;
      border-radius: $border-radius;
    }

    .info {
      z-index: 3;
      font-size: 0.875rem;
      background-color:#DC3545;
      padding: 0.25rem 1rem;
      position: absolute;
      top: -3px; right:0;
      color: $white;
      min-height: 30px;
      border-radius: 0 8px;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 2px;
      span {
        font-weight: 300;
      }
    }


    &:hover {
      &:after {
        transform: scale(1.1);
      }
      .img-wrapper {
        transform: scale(1.1);
      }
    }
  }
}

.img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.menu {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: .5rem;
  margin-right: .25rem;
}

.scroll {
  max-height: 600px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.scroll-multi-room {
  max-height: 700px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.scroll-room {
  max-height: 600px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.scroll-fan-letter {
  max-height: 550px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.text-filter {
  font-weight: 500;
}

.search-wrapper {
  padding: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.search-room-list {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.search-bar {
  margin-left: 1rem;
  position: absolute;
}

.room-list {
  background-color: #24a2b7; 
  color: white; 
  border-top: none;
}

footer {
  background-color: #21252b;

  border-top: 1px solid $gray-200;
  padding-top: 50px;

  .brand-tagline {
    color: $gray-500;
    margin-top: 8px;
  }
  
  .list-group-item {
    background-color: #21252b !important;
    padding: 0.3125rem 0;
    border: 0;
    a, span {
      color: $gray-500;
    }
    a {
      @include hover-focus {
        color: $cyan;
      }
    }
  }

  .copyrights {
    margin-top: 50px;
    color: $gray-500;
    margin-bottom: 60px;
  }
}

main {
  p {
    color: $gray-500;
    font-weight: 400;
  }
}

@media (max-width: 767px) {
  .navbar-nav {
    gap: 10px; 
    margin-top: 10px !important;
    margin-bottom: 24px !important;
    box-shadow: 0px 1px 5px rgba(192, 192, 192, 0.5);
  }

  .title {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .dark-mode {
    display: none !important;
  }

  .search-wrapper {
    padding-left: auto !important;
    padding-right: auto !important;
  }

  .btn-onlive {
    display: none !important;
   }
}
.input-with-icon {
  position: relative;
}

.input-with-icon input {
  padding-right: 2.5rem;
}

.input-with-icon .password-toggle-icon {
  position: absolute;
  top: 0;
  right: 0.5rem;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  color: #aaa;
  cursor: pointer;
}

.dashboard {
  display: flex;
  gap: 20px;
  padding-bottom: 25px;
}

.card-admin {
  padding: 16px;
  width: 280px;
  background-color: #24A2B7;
  border-radius: 15px;
}

.title {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.info {
  color: #ECFAFC;
  font-size: 24px;
  margin-top: 3px;
  font-weight: 300;
}

.menu-admin {
  display: flex;
  align-items: center;
  gap: 20px;
}

.btn-detail {
  margin-top: 2px;
  background-color: #ECFAFC;
  width: 100%;
  height: 30px;
  border-radius: 8px;
  color: #22a2b7;
  border: none;
  font-weight: 700;
}

@media (max-width: 767px) {
  .dashboard {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 50px;
  }

  .card-admin {
    padding: 16px;
    width: 100%;
    background-color: #24A2B7;
    border-radius: 15px;
  }
}

.theater-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.setlist-container {
  width: 100%;
  height: 92px;
  background-color: #008080;
  padding: 12px;
  border-radius: 8px;
  overflow: hidden;
}

.setlist-name {
  color: #ECFAFC;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 1450px) {
  .setlist-name {
    font-size: 18px;
  }
}

.setlist-subname {
  color: #ECFAFC;
  font-size: 16px;
  line-height: normal;
}

.menu-setlist {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 10px;
}

.info-container {
  width: 100%;
  height: 92px;
  background-color: #3B82F6;
  padding: 12px;
  border-radius: 8px;
  overflow: hidden;
}

.theater-info {
  display: flex;
  gap: 5px;
}

.info-theater {
  color: #ECFAFC;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.theater-time {
  color: #ECFAFC;
  font-size: 14px;
  line-height: normal;
}

.member-wrapper {
  border-radius: 6px;
  background: linear-gradient(180deg, #24A2B7 0%, #2563EB 100%);
  overflow: hidden;
}

.member-info-wrapper {
  display: flex;
  height: 112px;
  align-items: flex-start;
  gap: 13px;
  flex-shrink: 0;
}

.member-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.member-image {
  width: 65px;
  border-radius: 4px;
}

.btn-member {
  border-radius: 4px;
  background: #ECFAFC;
}

.member-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 21px;
  font-size: 14px;
  color: #24A2B7;
  font-weight: 700;
}

.card-member-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.lineup-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ECFAFC;
  color: #24A2B7;
  border: none;
  border-radius: 8px;
  height: 30px;
}

.menu-ticket {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  gap: 20px;
}

.ticket-info {
  display: flex;
  height: 96px;
  padding: 10px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #DC3545;
  overflow: hidden;
}

.birthday-btn {
  display: flex;
  padding: 10px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #24A2B7;
  overflow: hidden;
}

.ticket-name {
  font-weight: 700;
  font-size: 16px;
}

.buy {
  width: 100px;
  height: 47px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #ECFAFC;
  color: #DC3545;
  border: none;
  font-weight: 700;
}

.live-info-wrapper {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 6px;
}

.live-start {
  width: 100%;
  background-color: #2563EB;
  padding: 8px;
  align-items: center;
  border-radius: 8px;
}

.live-end {
  width: 100%;
  background-color: #24A2B7;
  padding: 8px;
  border-radius: 8px;
}

.live-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.live-duration {
  width: 100%;
  height: 80px;
  border-radius: 8px;
  background: #DC3545;
  padding: 6px;
}

.live-date {
  width: 100%;
  height: 80px;
  border-radius: 8px;
  background: #008080;
  padding: 6px;
}

.duration-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
}

.main-title-log {
  border-radius: 8px;
  background: linear-gradient(180deg, #008080 0%, #24A2B7 100%);
  padding: 10px;
}

.main-title-text {
  color: #ECFAFC;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.viewers {
  width: 100%;
  background-color: #DC3545;
  padding: 8px;
  align-items: center;
  border-radius: 8px;
}

.gift {
  width: 100%;
  background-color: #F76F07;
  padding: 8px;
  align-items: center;
  border-radius: 8px;
}

.viewers-wrapper {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 10px;
}

.top-gift-card {
  background: linear-gradient(180deg, #3B82F6 0%, #24A2B7 100%);
  border-radius: 6px;
  padding: 15px;
}

.top-gift-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.gift-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  overflow: hidden;
}

.card-schedule {
  border-radius: 0px 0px 6px 6px;
  background: linear-gradient(180deg, #24A2B7 0%, #3B82F6 100%);
  height: 147.564px;
  padding: 14px;
  overflow: hidden;
}

.card-desc {
  font-weight: 600;
  color: white;
}

.theater-date {
  border-radius: 10px;
  background: #865CD6;
  padding: 8px;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 14px;
  color: white;

}

.theater-schedule-title {
  border-radius: 10px;
  background: #008080;
  padding: 8px;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 18px;
  color: white;

}

.theater-button {
  margin-top: 8px;
  border-radius: 6px;
  background-color: #ECFAFC;
  width: 100%;
  height: 30px;
  color:#24A2B7;
  font-weight: bold;
  border: none;
}

.scroll-menu-container {
  position: relative;
  overflow-x: hidden;
}

.arrow-button {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  cursor: pointer;
  color: "#4A5568"; /* Text color for buttons */
  border: none;
  outline: none;
  padding: 10px;
  z-index: 1;
  transition: box-shadow 0.3s ease; 
}

.arrow-button.left {
  left: -8px;
}

.arrow-button.right {
  right: -10px;
}

.recent-live-container {
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 20px;
  scroll-behavior: smooth; /* Enable smooth scrolling */
  transition: scroll-left 0.3s ease; /* Add smooth scroll transition */
}

.card-recent-live {
  height: 150px;
  display: flex;
  flex-shrink: 0;
  border-radius: 6px;
  gap: 6px;
  background: linear-gradient(180deg,#004A66  0%,  #009FCB 100%);
}

.recent-card-name {
  margin-top: 8px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 6px;
  background: $teal;
  padding: 4px;
  overflow: hidden;
}

.recent-name {
  display: flex;
  align-items: center;
  gap: 3px;
  color: #ECFAFC;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: 3px;
}

.last-live {
  display: flex;
  align-items: center;
  margin-top: 8px;
  border-radius: 6px;
  background: #DC3545;
  padding: 4px;
  overflow: hidden;
}

.recent-image {
  border-radius: 6px 0px 0px 6px;
}

.recent-info-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
}

.recent-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.recent-live {
  background: linear-gradient(180deg, #4724B7 0%, #24A2B7 100%);
  stroke-width: 1px;
  stroke: #ECFAFC;
  border-radius: 6px;
}

.img-setlist {
   .img-wrapper {
      z-index: 1;
      margin: 0;
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      transform: scale(1);
      transition: 0.25s transform ease-in-out;
      border-radius: $border-radius;
    }
  &:hover {
    .meta-wrapper {
      transform: translateY(-10px);
    }
    &:after {
      transform: scale(1.1);
    }
    .img-wrapper {
      transform: scale(1.1);
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spin-animation {
  animation: spin 2s infinite linear;
}

.menu-button {
  background-color: teal !important;
  border: none !important;
  border-radius: 5px !important;
}

@media only screen and (min-width: 1200px) {
  .header-wrapped {
    padding-left: 20px;
  }
}

.logo-wrapped {
  display: inline-flex;
  height: 61.335px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}

.wrapped-title {
  color: #24A2B7;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media only screen and (max-width: 350px) {
  .wrapped-title {
    font-size: 16px;
  }
}

.web-title {
  color: #D9D9D9;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.wrapped-container {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

@media only screen and (max-width: 600px) {
  .wrapped-container {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.rounded-large {
  border-radius: 10px;
}

.list-donator {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  background: linear-gradient(180deg, #3B82F6 0%, #24A2B7 100%);
}

.user-donate {
  display: flex;
  align-items: center;
  gap: 7px;
}

.donator-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.donator-name {
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  width: auto;
  border-radius: 4px;
  background: #ECFAFC;
}

.donator-text {
  color: #24A2B7;
  font-weight: 700;
  font-size: 16px;
}

.select-room {
  background: linear-gradient(180deg, #3B82F6 0%, #24A2B7 100%);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.active-nav-idn {
  font-size: 16px;
  color: #ECFAFC;
  background-color: #DC3545;
  font-weight: 700;
}

.inactive-nav {
  font-size: 16px;
}

.podium {
  border-radius: 8px;
  background: linear-gradient(180deg, #1F8D9D 0%, #24A2B7 100%);
  display: flex;
  justify-content: center;
}

.stage-name {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
}

.podium-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.podium-name {
  border-radius: 4px;
  background: #ECFAFC;
  padding: 0 4px 1px 6px;
  color: #008080;
  font-weight: 600;
}

.podium-views {
  display: flex;
  top: 1;
  left: 0;
  z-index: 99;
  width: 30px;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #ECFAFC;
  color: #008080;
  border-radius: 100%;
  padding: 2px;
}

.podium-leaderboard {
  display: flex;
  top: 1;
  right: 0;
  z-index: 99;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #ECFAFC;
  color: #008080;
  border-radius: 100%;
  padding: 2px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.dropdown-toggle::after {
  margin-left: 8px !important;
}

.leaderboard-text {
  font-size: 26px;
  font-weight: bold;
}

.leaderboard-date {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: teal;
  padding: 8px;
  border-radius: 12px;
  margin-right: 10px;
  font-size: 14px;
}

.leaderboard-total {
  display: flex;
  gap: 8px;
  align-items: center;
  background: linear-gradient(120deg, #004A66 0%, #009FCB 100%);
  padding: 8px;
  border-radius: 12px;
  margin-right: 10px;
  font-size: 14px;
}